import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import { GatsbyImage } from "gatsby-plugin-image"

import Gallery from "@browniebroke/gatsby-image-gallery"

import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Video from "../../components/Video/Video"
import Seo from "../../components/Seo"

const PublicOutreachEventPage = ({
  data: { previous, next, publicOutreachEvent },
}) => {
  const images = publicOutreachEvent.PublicOutreachGQL.imagesGallery?.map(
    image => image.localFile.childImageSharp
  )

  return (
    <React.Fragment>
      <Seo
        title={publicOutreachEvent.PublicOutreachGQL.title}
        description={""}
        image={
          publicOutreachEvent.PublicOutreachGQL.thumbnailImage.localFile
            .childImageSharp.gatsbyImageData.images.fallback.src
        }
      />

      <Navbar />
      <article className="marginTopNavbar">
        <div className="main-container">
          <header className="news-header">
            <h1 className="news-header__title">
              {parse(publicOutreachEvent.PublicOutreachGQL.title)}
            </h1>
            <p className="news-header__date">
              <time>{publicOutreachEvent.date}</time>
            </p>

            <div className="grid-8-col">
              <div className="col-2"></div>

              <GatsbyImage
                draggable="false"
                className="col-4 image-border news-header__image"
                alt={publicOutreachEvent.PublicOutreachGQL.title}
                image={
                  publicOutreachEvent.PublicOutreachGQL.thumbnailImage.localFile
                    .childImageSharp.gatsbyImageData
                }
              />
            </div>
            <span className="news-header__scroll">Scroll Down ↓</span>
          </header>
        </div>
        <div className="horizontal-line" />
        <div className="main-container">
          <section className="news-body grid-8-col">
            <div className="col-1"></div>
            <div className="col-4">
              {parse(publicOutreachEvent.PublicOutreachGQL.text)}

              {publicOutreachEvent.PublicOutreachGQL.imagesGallery && (
                <>
                  <br />
                  <br />
                  <h2>Image Gallery</h2>
                  <br />
                  <Gallery
                    // colWidth={"25%"}
                    // mdColWidth={"50%"}
                    rowMargin={0}
                    images={images}
                  />
                </>
              )}

              {publicOutreachEvent.PublicOutreachGQL.videoGallery && (
                <>
                  <br />
                  <br />
                  <h2>Videos</h2>
                  <br />
                  {publicOutreachEvent.PublicOutreachGQL.videoGallery.map(
                    (v, vIndex) =>
                      v.video?.mediaItemUrl && (
                        <Video
                          key={vIndex}
                          videoSrcURL={v.video.mediaItemUrl}
                          videoTitle={v.video.title}
                        />
                      )
                  )}
                  <br />
                  {publicOutreachEvent.PublicOutreachGQL.videoGallery.map(
                    (vi, vIndex) =>
                      vi.videoUrl !== null && (
                        <Video
                          key={vIndex}
                          videoSrcURL={vi.videoUrl}
                          videoTitle={""}
                        />
                      )
                  )}
                </>
              )}
            </div>
          </section>
        </div>
        <div className="horizontal-line" />
        <div className="main-container">
          <nav className="link-pages-nav">
            <ul className="link-pages-nav__ul">
              <li className="link-pages-nav__ul__left">
                {previous && (
                  <>
                    <span>Previous</span> <br />
                    <Link to={previous.uri} rel="prev">
                      {parse(previous.title)}
                    </Link>
                  </>
                )}
              </li>
              <li className="link-pages-nav__ul__center">
                <Link to="/public-outreach">More Public Outreach</Link>
              </li>
              <li className="link-pages-nav__ul__right">
                {next && (
                  <>
                    <span>Next</span> <br />
                    <Link to={next.uri} rel="next">
                      {parse(next.title)}
                    </Link>
                  </>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </article>
      <Footer />
    </React.Fragment>
  )
}

export default PublicOutreachEventPage

export const pageQuery = graphql`
  query PublicOutreachEventById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    publicOutreachEvent: wpPublicOutreachEvent(id: { eq: $id }) {
      id
      title
      date(formatString: "MMMM DD, YYYY")
      PublicOutreachGQL {
        title
        text
        thumbnailImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        imagesGallery {
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 270
                height: 270
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        videoGallery {
          video {
            title
            mediaItemUrl
          }
          videoUrl
        }
      }
    }

    previous: wpPublicOutreachEvent(id: { eq: $previousPostId }) {
      uri
      title
    }

    next: wpPublicOutreachEvent(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
